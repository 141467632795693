.our-location {
  width: 100%;
  margin: 0;
  margin-left: -2px;
}

.our-location h2 {
  position: relative;
  text-align: center;
  font-size: 40px;
  margin-bottom: 50px;
  text-transform: uppercase;
  font-family: var(--font-secondary);
  font-weight: 500;
  letter-spacing: 1px;
  color: var(--white);
  z-index: 5;
}

.our-location h2 span {
  color: var(--light-bronz);
}

.bronz-box {
  width: 85%;
  position: absolute;
  height: 85%;
  top: 8%;
  left: 11%;
  border: 2px solid var(--light-bronz);
}

.col-small {
  width: 1365px;
  padding: 4em;
  padding-top: 6em;
  padding-left: 15em;
  background-image: url('../../assets/images/StarlifeGreenSkyscraperBG.png');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.col-small p {
  position: relative;
  font-family: var(--font-primary);
  color: var(--white);
  letter-spacing: 1px;
  line-height: 25px;
  font-size: 17px;
  margin-bottom: 20px;
  z-index: 5;
}

@media screen and (max-width: 2600px) and (min-width: 1930px) {
  .col-small {
    width: 87%;
    padding: 7em;
    padding-left: 25em;
  }
  .our-location h2 {
    font-size: 60px;
  }
  .col-small p {
    line-height: 40px;
    font-size: 27px;
  }
  .bronz-box {
    left: 13%;
    width: 83%;
  }
}

@media screen and (max-width: 1920px) and (min-width: 1600px) {
  .col-small {
    width: 81.5%;
  }
  .col-small {
    padding-left: 27em;
  }
  .bronz-box {
    left: 19%;
    width: 77%;
  }
}

@media screen and (max-width: 1500px) and (min-width: 1300px) {
  .bronz-box {
    left: 6%;
    width: 90%;
  }
  .col-small {
    padding-left: 8em;
  }
}

@media screen and (max-width: 500px) {
  .col-small {
    width: 100%;
    padding: 2em;
    padding-top: 4em;
  }
  .bronz-box {
    width: 93%;
    height: 95%;
    top: 3%;
    left: 4%;
  }
}
