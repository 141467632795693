.stay-connected-section p {
  text-align: center;
  font-family: var(--font-primary);
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 10px;
}

.stay-connected-section .input-group label {
  color: var(--white);
  background: linear-gradient(to bottom, var(--light-green), var(--dark-green));
  cursor: pointer;
  text-align: center;
}

.stay-connected-section .col-3 {
  width: 100%;
  margin-top: 2em;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 2600px) and (min-width: 1930px) {
  .stay-connected-section p {
    font-size: 27px;
    margin-bottom: 35px;
    margin-top: -20px;
  }
}
